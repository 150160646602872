import styled from 'styled-components';

import { Color } from '@lerna-monorepo/common/theme';
import { CaseStudySection } from '@lerna-monorepo/common/components/caseStudySection/caseStudySection.component';

export const DynamicContentGroupContainerTop = styled.div`
  padding: 100px 0 0;
  background-color: ${Color.veryLightGrey2};
`;

export const DynamicContentGroupContainerBottom = styled.div`
  padding: 0 0 150px;
  background-color: ${Color.veryLightGrey2};
  position: relative;
`;

export const DynamicContentComponentContainer = styled.div`
  & + & {
    padding-top: 100px;
  }
`;

export const ContactFormSectionWrapper = styled.div`
  background-color: ${Color.veryLightGrey2};
  padding: 100px 0;
`;

export const HalfBox = styled.div`
  width: 50%;
  background-color: ${Color.white};
  height: 45px;
  position: absolute;
  bottom: 0;
  right: 0;
`;
